import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

import { CONTRACT_HISTORY_TABLE_SORTABLE_COLUMNS } from "@js/apps/employer/components/contracts-history-table/columns";
import type { ContractHistoryTableParamsOrderBy } from "@js/apps/employer/components/contracts-history-table/types";
import { Snackbar } from "@js/components/snackbar";
import { useParsedQueryParamsWithPrefixAndDefaults } from "@js/hooks";
import { useEffectRef } from "@js/hooks/use-effect-ref";
import { clearEmpties } from "@js/utils";

// CONTRACT_HISTORY_TABLE_QUERY_PARAM_PREFIX - prefix for query params, needed as there are multiple query params for different tables on the same page
export const CONTRACT_HISTORY_TABLE_QUERY_PARAM_PREFIX =
  "contract_history_" as const;

const schema = z.object({
  page: z.coerce.number().positive().int().optional(),
  page_size: z.coerce.number().positive().int().optional(),
  order_by: z.enum(CONTRACT_HISTORY_TABLE_SORTABLE_COLUMNS).optional(),
  order_dir: z.enum(["asc", "desc"]).optional(),
});

export type ContractHistoryFilters = z.infer<typeof schema>;

export const useContractHistoryTableFilters = () => {
  const [, setSearchParams] = useSearchParams();

  const setSearchParamsRef = useEffectRef(setSearchParams);

  const { data, success } = useParsedQueryParamsWithPrefixAndDefaults(
    schema,
    CONTRACT_HISTORY_TABLE_QUERY_PARAM_PREFIX,
    {
      page: 1,
      page_size: 10,
    },
  );

  useEffect(() => {
    if (!success) {
      Snackbar.error(
        "Invalid filters detected. Please try again with different settings.",
      );
      setSearchParamsRef.current(
        new URLSearchParams({
          [`${CONTRACT_HISTORY_TABLE_SORTABLE_COLUMNS}page`]: "1",
          [`${CONTRACT_HISTORY_TABLE_SORTABLE_COLUMNS}page_size`]: "10",
        }),
        { state: { disableRemountIfTheSameLocation: true } },
      );
    }
  }, [setSearchParamsRef, success]);

  const handleSortChange = ({
    sortBy,
    sortDir,
  }: {
    sortBy: ContractHistoryTableParamsOrderBy | undefined;
    sortDir: "asc" | "desc" | undefined;
  }) => {
    setSearchParams(
      (prevParams) => {
        const oldParamsObject = Object.fromEntries(
          new URLSearchParams(prevParams).entries(),
        );

        const newParamsObject: ContractHistoryFilters = {
          ...oldParamsObject,
          [`${CONTRACT_HISTORY_TABLE_QUERY_PARAM_PREFIX}order_by`]: sortBy,
          [`${CONTRACT_HISTORY_TABLE_QUERY_PARAM_PREFIX}order_dir`]: sortDir,
        };

        clearEmpties(newParamsObject);

        return new URLSearchParams(newParamsObject as Record<string, string>);
      },
      { state: { disableRemountIfTheSameLocation: true } },
    );
  };

  return { filters: data, handleSortChange };
};
