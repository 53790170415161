/* eslint-disable */

/*
    *************************************************************************
    *
    * This file is generated by script, can't be modified manually
    *
    *************************************************************************
*/

export const GENERATED_ENUMS = {
    ConfirmAction: {
        CONFIRM_CHANGE_EMAIL: "confirm_change_email",
        DELETE_ACCOUNT: "delete_account",
        CONFIRM_SIGNUP_EMAIL: "confirm_signup_email",
        CONFIRM_WM: "confirm_wm",
        SET_WM_AS_DEFAULT: "set_wm_as_default",
    },
    // This file is generated by script, can't be modified manually
    CustomPermission: {
        CAN_ACCESS_DATA_FOR_TALENT_REPORT: "can_access_data_for_talent_report",
        CAN_ACCESS_ONRAMP: "can_access_onramp",
        CAN_ACCESS_TALENT_LOCATION_REPORT: "can_access_talent_location_report",
        CAN_GRANT_TOKENS: "can_grant_tokens",
        CAN_MANAGE_NOTIFICATIONS: "can_manage_notifications",
        CAN_MANAGE_TOKEN_GRANT_PLANS: "can_manage_token_grant_plans",
        CAN_MANAGE_TOKEN_TAXES_REPORT: "can_manage_token_taxes_report",
        CAN_MARK_INVOICE_AS_PAID_OFF_PLATFORM: "can_mark_invoice_as_paid_off_platform",
        CAN_REMOVE_REFERRED_EMPLOYER_LINK: "can_remove_referred_employer_link",
        CAN_RETRY_WITHDRAWAL: "can_retry_withdrawal",
        CAN_VIEW_ALL_JOBS_REPORT: "can_view_all_jobs_report",
        CAN_VIEW_BID_REPORT: "can_view_bid_report",
        CAN_VIEW_CUSTOMER_INVOICE_REPORT: "can_view_customer_invoice_report",
        CAN_VIEW_DEPOSIT_REPORT: "can_view_deposit_report",
        CAN_VIEW_EMPLOYER_INVOICES: "can_view_employer_invoices",
        CAN_VIEW_HELP_OFFER_PAYMENTS_REPORT: "can_view_help_offer_payments_report",
        CAN_VIEW_INVOICE_HISTORY: "can_view_invoice_history",
        CAN_VIEW_MONEY_FLOW_DASHBOARD: "can_view_money_flow_dashboard",
        CAN_VIEW_OFFER_REPORT: "can_view_offer_report",
        CAN_VIEW_REFERRAL_CLIENT_OPT_INS_REPORT: "can_view_referral_client_opt_ins_report",
        CAN_VIEW_REFERRALS_REPORT: "can_view_referrals_report",
        CAN_VIEW_REWARDS_CENTER: "can_view_rewards_center",
        CAN_VIEW_TALENTS_WITH_JOBS_REPORT: "can_view_talents_with_jobs_report",
        CAN_VIEW_TOKEN_BALANCE_REPORT: "can_view_token_balance_report",
        CAN_VIEW_TOKEN_GRANT_PLANS: "can_view_token_grant_plans",
        CAN_VIEW_TOKEN_WITHDRAWALS: "can_view_token_withdrawals",
        CAN_VIEW_TOP_JOB_SKILLS_REPORT: "can_view_top_job_skills_report",
        CAN_VIEW_USERS_INTERESTED_IN_REPORTS: "can_view_users_interested_in_reports",
    },
    // This file is generated by script, can't be modified manually
    CustomPermissionLabels: {
        can_access_data_for_talent_report: "Can access data for talent report",
        can_access_onramp: "Can Access On Ramp",
        can_access_talent_location_report: "Can access talent location report",
        can_grant_tokens: "Can grant BTRSTs",
        can_manage_notifications: "Can manage notifications",
        can_manage_token_grant_plans: "Can manage Token Grant Plans",
        can_manage_token_taxes_report: "Can manage Token Taxes Report",
        can_mark_invoice_as_paid_off_platform: "Can mark Invoice as paid off platform",
        can_remove_referred_employer_link: "Can Remove Referred Employer Link",
        can_retry_withdrawal: "Can retry Withdrawal",
        can_view_all_jobs_report: "Can view all jobs report",
        can_view_bid_report: "Can view bid report",
        can_view_customer_invoice_report: "Can view customer invoice report",
        can_view_deposit_report: "Can view Deposit Report",
        can_view_employer_invoices: "Can View Employer Invoices",
        can_view_help_offer_payments_report: "Can view Help Offer Payments Report",
        can_view_invoice_history: "Can view Invoice History",
        can_view_money_flow_dashboard: "Can view money flow dashboard",
        can_view_offer_report: "Can view Offer Report",
        can_view_referral_client_opt_ins_report: "Can View Referral Client Opt Ins Report",
        can_view_referrals_report: "Can view referrals report",
        can_view_rewards_center: "Can view Rewards Center",
        can_view_talents_with_jobs_report: "Can view talents with jobs report",
        can_view_token_balance_report: "Can view Token Balance Report",
        can_view_token_grant_plans: "Can view Token Grant Plans",
        can_view_token_withdrawals: "Can view BTRST Withdrawals",
        can_view_top_job_skills_report: "Can View Top Job Skills Report",
        can_view_users_interested_in_reports: "Can View Users Interested In Reports",
    },
    // This file is generated by script, can't be modified manually
    NoteContentType: {
        Bid: "Bid",
        Freelancer: "Freelancer",
        Job: "Job",
        Offer: "Offer",
    },
    // This file is generated by script, can't be modified manually
    PaymentContentType: {
        StripeACHPaymentMethod: "StripeACHPaymentMethod",
        StripeCreditCardPaymentMethod: "StripeCreditCardPaymentMethod",
    },
    // This file is generated by script, can't be modified manually
    ProfileCompletionArea: {
        FreelancerFullNameCompletionDetail: "Full Name",
        FreelancerLocationCompletionDetail: "Location",
        FreelancerRoleCompletionDetail: "Role",
        FreelancerSkillCompletionDetail: "Skills",
        FreelancerSuperPowersCompletionDetail: "Super Powers",
        FreelancerWorkExperienceCompletionDetail: "Work History",
    },
    // This file is generated by script, can't be modified manually
    RenameableAttachment: {
        BID_ATTACHMENT_UPLOAD_TYPE: "bid_attachment_upload_type",
        RESUME_ATTACHMENT_UPLOAD_TYPE: "resume_attachment_upload_type",
    },
    // This file is generated by script, can't be modified manually
    RewardRuleContentType: {
        ConnectorReferredEmployerPaidForInvoiceRewardType: "ConnectorReferredEmployerPaidForInvoiceRewardType",
        FreelancerApprovedRewardType: "FreelancerApprovedRewardType",
        ManualRewardType: "ManualRewardType",
        ReferredEmployerPaidForInvoiceRewardType: "ReferredEmployerPaidForInvoiceRewardType",
        ReferredFreelancerApprovedRewardType: "ReferredFreelancerApprovedRewardType",
        ReferredFreelancerHasTheirInvoicePaidRewardType: "ReferredFreelancerHasTheirInvoicePaidRewardType",
        ReferTalentToAJobRewardType: "ReferTalentToAJobRewardType",
    },
    // This file is generated by script, can't be modified manually
    RoomTypeContentType: {
        P2P_ROOM_TYPE: "p2p room type",
    },
    // This file is generated by script, can't be modified manually
    TrackingEvents: {
        ACCEPT_OFFER: "accept_offer",
        ADD_ATTACHMENT_REPLY: "add_attachment_reply",
        ADD_BIO: "add_bio",
        ADD_BUDGET: "add_budget",
        ADD_CATEGORY: "add_category",
        ADD_CERTIFICATES: "add_certificates",
        ADD_CONTENT: "add_content",
        ADD_EDUCATION: "add_education",
        ADD_FILE: "add_file",
        ADD_IMAGE: "add_image",
        ADD_LIFESTYLE_PHOTO: "add_lifestyle_photo",
        ADD_LINK: "add_link",
        ADD_OFFER_PRICE_REPLY: "add_offer_price_reply",
        ADD_OFFER_REPLY: "add_offer_reply",
        ADD_POLL: "add_poll",
        ADD_POLL_OPTIONS: "add_poll_options",
        ADD_POLL_QUESTION: "add_poll_question",
        ADD_PROFILE_PHOTO: "add_profile_photo",
        ADD_PROJECT: "add_project",
        ADD_TITLE: "add_title",
        ADD_WORK_HISTORY: "add_work_history",
        APPROVE_TALENT: "approve_talent",
        BACK_TO_FIRST_JOB_POSTING_SCREEN: "back_to_first_job_posting_screen",
        BACK_TO_SECOND_JOB_POSTING_SCREEN: "back_to_second_job_posting_screen",
        BLOCKED_EMAIL_DOMAIN_SIGNUP_ATTEMPT: "blocked_email_domain_signup_attempt",
        CANCEL_OFFER: "cancel_offer",
        CANCEL_PROPOSAL: "cancel_proposal",
        CHOOSE_PERSONAL_ACCOUNT: "choose_personal_account",
        CLICK_ADD_INTEGRATION_ATS: "click_add_integration_ats",
        CLICK_ADD_TEAM_MEMBERS: "click_add_team_members",
        CLICK_APPLICATION_AVAILABILITY: "click_application_availability",
        CLICK_APPLY_SEARCH: "click_apply_search",
        CLICK_AVAILABLE_TOGGLE_OFF: "click_available_toggle_off",
        CLICK_AVAILABLE_TOGGLE_ON: "click_available_toggle_on",
        CLICK_BOOST_BANNER: "click_boost_banner",
        CLICK_BOOST_BUTTON: "click_boost_button",
        CLICK_BOOST_PURCHASE_MODAL: "click_boost_purchase_modal",
        CLICK_CAREER_HELP_THROUGH_MY_POSTS: "click_career_help_through_my_posts",
        CLICK_CLIENT_INVITE_SIGNUP_AGREE_TERMS: "click_client_invite_signup_agree_terms",
        CLICK_CLIENT_INVITE_SIGNUP_CONTINUE: "click_client_invite_signup_continue",
        CLICK_CLIENT_INVITE_SIGNUP_JOIN: "click_client_invite_signup_join",
        CLICK_CLIENT_INVITE_SIGNUP_ORG_DETAILS: "click_client_invite_signup_org_details",
        CLICK_CLIENT_SIGNUP_AGREE_TERMS: "click_client_signup_agree_terms",
        CLICK_CLIENT_SIGNUP_JOIN: "click_client_signup_join",
        CLICK_CLIENT_SIGNUP_ORG_DETAILS: "click_client_signup_org_details",
        CLICK_CREATE_JOB_HOME_DASHBOARD: "click_create_job_home_dashboard",
        CLICK_CREATE_JOB_MY_JOBS: "click_create_job_my_jobs",
        CLICK_DIRECT_HIRE_CHECK: "click_direct_hire_check",
        CLICK_DISCONNECT_ATS: "click_disconnect_ats",
        CLICK_DOWNLOAD_INVOICES: "click_download_invoices",
        CLICK_EVENT_CREATE_AI: "click_event_create_ai",
        CLICK_EVENT_CREATE_CUSTOM: "click_event_create_custom",
        CLICK_EVENT_CREATE_UPLOAD: "click_event_create_upload",
        CLICK_EXPAND_DETAILS_SEARCH: "click_expand_details_search",
        CLICK_FULL_TIME_CHECK: "click_full_time_check",
        CLICK_GET_INSPIRED: "click_get_inspired",
        CLICK_GLOBAL_SEARCH: "click_global_search",
        CLICK_INSIGHTS_BANNER: "click_insights_banner",
        CLICK_INSIGHTS_PURCHASE_MODAL: "click_insights_purchase_modal",
        CLICK_INSIGHTS_TOOLTIP: "click_insights_tooltip",
        CLICK_INTERESTED: "click_interested",
        CLICK_INVOICE_CSV: "click_invoice_csv",
        CLICK_INVOICES_COLUMNS: "click_invoices_columns",
        CLICK_INVOICES_SEARCH: "click_invoices_search",
        CLICK_JOB_CARD_BROWSE_JOBS: "click_job_card_browse_jobs",
        CLICK_JOB_RESULTS_POPOVER_SEARCH: "click_job_results_popover_search",
        CLICK_JOB_TITLE_SEARCH: "click_job_title_search",
        CLICK_JOB_VIEW_ALL_POPOVER_SEARCH: "click_job_view_all_popover_search",
        CLICK_MOVE_TO_DRAFTS_ATS: "click_move_to_drafts_ats",
        CLICK_MY_TALENT_COLUMNS: "click_my_talent_columns",
        CLICK_MY_TALENT_CSV: "click_my_talent_csv",
        CLICK_MY_TALENT_SEARCH: "click_my_talent_search",
        CLICK_MY_TALENT_SHOW_CONTRACTS: "click_my_talent_show_contracts",
        CLICK_MY_TALENT_SHOW_TALENT: "click_my_talent_show_talent",
        CLICK_MY_TALENT_VIEW_JOBS: "click_my_talent_view_jobs",
        CLICK_NAV_ATS_INTEGRATION: "click_nav_ats_integration",
        CLICK_NEXT_STEP: "click_next_step",
        CLICK_ONRAMP_BOOST: "click_onramp_boost",
        CLICK_ONRAMP_INSIGHTS: "click_onramp_insights",
        CLICK_ONRAMP_WALLET: "click_onramp_wallet",
        CLICK_PART_TIME_CHECK: "click_part_time_check",
        CLICK_PEOPLE_RESULTS_POPOVER_SEARCH: "click_people_results_popover_search",
        CLICK_POST_HASHTAG: "click_post_hashtag",
        CLICK_POST_JOB: "click_post_job",
        CLICK_POST_OPTION: "click_post_option",
        CLICK_POST_RESULTS_POPOVER_SEARCH: "click_post_results_popover_search",
        CLICK_POST_VIEW_ALL_POPOVER_SEARCH: "click_post_view_all_popover_search",
        CLICK_PUBLISH_JOBS_ATS: "click_publish_jobs_ats",
        CLICK_REFERRAL_CLIENT_CSV: "click_referral_client_csv",
        CLICK_REFERRAL_FAQ: "click_referral_faq",
        CLICK_REFERRAL_TALENT_CSV: "click_referral_talent_csv",
        CLICK_REPLY: "click_reply",
        CLICK_REPORT_POST: "click_report_post",
        CLICK_RESYNC_ATS: "click_resync_ats",
        CLICK_REUPLOAD_LINKEDIN_CSV: "click_reupload_linkedin_csv",
        CLICK_REVIEW_JOB: "click_review_job",
        CLICK_SELECT_JOBS_ATS: "click_select_jobs_ats",
        CLICK_SEND_REPORT_POST: "click_send_report_post",
        CLICK_SET_AVAILABILITY: "click_set_availability",
        CLICK_SET_UP_NEW_JOB: "click_set_up_new_job",
        CLICK_SHARE_APPLICANT: "click_share_applicant",
        CLICK_SHARE_APPLICANT_BULK: "click_share_applicant_bulk",
        CLICK_SHARE_PROFILE: "click_share_profile",
        CLICK_SYNC_JOBS_ATS_HOME_DASHBOARD: "click_sync_jobs_ats_home_dashboard",
        CLICK_TALENT_INVITE_SEARCH: "click_talent_invite_search",
        CLICK_TALENT_NAME_HOVER: "click_talent_name_hover",
        CLICK_TALENT_POPOVER_SEARCH: "click_talent_popover_search",
        CLICK_TALENT_SEARCH: "click_talent_search",
        CLICK_TALENT_VIEW_ALL_POPOVER_SEARCH: "click_talent_view_all_popover_search",
        CLICK_TRENDING_HASHTAG: "click_trending_hashtag",
        CLICK_UPDATE_AVAILABILITY_MENU: "click_update_availability_menu",
        CLICK_UPDATE_AVAILABILITY_PROFILE: "click_update_availability_profile",
        CLICK_UPLOAD_JD: "click_upload_JD",
        CLICK_UPLOAD_LINKEDIN_CSV: "click_upload_linkedin_csv",
        CLICK_USER_PROFILE: "click_user_profile",
        CLICK_VIEW_JOB_POPOVER_SEARCH: "click_view_job_popover_search",
        CLICK_VIEW_JOB_SEARCH: "click_view_job_search",
        CLICK_VIEW_POST_POPOVER_SEARCH: "click_view_post_popover_search",
        CLICK_VIEW_PROFILE_HOVER: "click_view_profile_hover",
        CLICK_WELCOME_MODAL_CLOSE: "click_welcome_modal_close",
        CLICK_WELCOME_MODAL_LEARN_MORE: "click_welcome_modal_learn_more",
        CLICK_WELCOME_MODAL_POST_JOB: "click_welcome_modal_post_job",
        CLIENT_REFERRAL_COPY_LINK: "client_referral_copy_link",
        CLIENT_REFERRAL_EMAIL_LINK: "client_referral_email_link",
        CLIENT_REFERRAL_LINKEDIN_LINK: "client_referral_linkedin_link",
        CLIENT_REFERRAL_TWITTER_LINK: "client_referral_twitter_link",
        CLIENT_SIGN_UP_SELECTED: "client_sign_up_selected",
        CLOSE_POST_COMPOSER: "close_post_composer",
        COLLAPSE_POST: "collapse_post",
        CONTINUE_AFTER_FIRST_NAME: "continue_after_first_name",
        CONTINUE_AFTER_INTEREST: "continue_after_interest",
        COPY_LINK: "copy_link",
        COPY_REFERRAL_TEMPLATE: "copy_referral_template",
        CREATE_ACCOUNT: "create_account",
        DEBUG__NEW_SESSION_START: "debug__new_session_start",
        DEBUG__ON_INIT: "debug__on_init",
        DECLINE_OFFER: "decline_offer",
        DECLINE_PROPOSAL: "decline_proposal",
        DELETE_JOB_DRAFT_CREATION_FLOW: "delete_job_draft_creation_flow",
        EDIT_PROFILE: "edit_profile",
        EMAIL_CLICK: "email_click",
        EMAIL_OPEN: "email_open",
        ENTER_EXPERIENCE: "enter_experience",
        ENTER_LOCATION: "enter_location",
        EXPAND_POST: "expand_post",
        FACT_1_VIEWED: "fact_1_viewed",
        FILTER_APPLIED: "filter_applied",
        FILTER_INVOICES_SEARCH: "filter_invoices_search",
        FILTER_MY_TALENT_SEARCH: "filter_my_talent_search",
        FILTER_SAVED: "filter_saved",
        FILTER_SEARCH: "filter_search",
        FINISH_CREATE_POST: "finish_create_post",
        IN_INTERVIEWING_STATUS: "in_interviewing_status",
        INITIATE_POST_COMPOSER: "initiate_post_composer",
        INTEND_INTERVIEW: "intend_interview",
        INVOICE_SORT_ORDER: "invoice_sort_order",
        JOB_NOTIFICATION_EMAIL_CLICKED: "job_notification_email_clicked",
        JOB_NOTIFICATION_EMAIL_SENT: "job_notification_email_sent",
        JOB_NOTIFICATION_EMAIL_VIEWED: "job_notification_email_viewed",
        JOB_REPOST: "job_repost",
        JOB_SAVED: "job_saved",
        MADE_OFFER: "made_offer",
        MAIL_CAREER_HELP_PERSONALIZED_NUDGE_CONDITIONALLY_SKIPPED: "mail_career_help_personalized_nudge_conditionally_skipped",
        MAIL_CAREER_HELP_PERSONALIZED_NUDGE_SENT: "mail_career_help_personalized_nudge_sent",
        MY_TALENT_SORT_ORDER: "my_talent_sort_order",
        NAV_ITEM_CLICK: "nav_item_click",
        OUTBOUND_VIEW_JOB_DETAILS: "outbound_view_job_details",
        POST_APPLICATION_SUGGESTED_POST_NOTIFICATION_EMAIL_VIEWED: "post_application_suggested_post_notification_email_viewed",
        POST_APPLICATION_SUGGESTED_POSTS_EMAIL_LINK_CLICKED: "post_application_suggested_posts_email_link_clicked",
        POST_SORT_ORDER_RECENT: "post_sort_order_recent",
        POST_SORT_ORDER_TOP: "post_sort_order_top",
        PROFILE_CLICK: "profile_click",
        PROFILE_COMPLETE: "profile_complete",
        PROFILE_VIEWED: "profile_viewed",
        REFERRAL_CLIENT_SORT_ORDER: "referral_client_sort_order",
        REFERRAL_TALENT_SORT_ORDER: "referral_talent_sort_order",
        RESPOND_TO_POST: "respond_to_post",
        SAVE_POST: "save_post",
        SELECT_ACCENT_COLOR: "select_accent_color",
        SELECT_ACCOUNT_TYPE: "select_account_type",
        SELECT_ENGLISH_PROFICIENCY: "select_english_proficiency",
        SELECT_EXPERIENCE: "select_experience",
        SELECT_ROLE_L1: "select_role_L1",
        SELECT_ROLE_L2: "select_role_L2",
        SELECT_SIGN_UP_REASONS: "select_sign_up_reasons",
        SELECT_SKILL: "select_skill",
        SELECT_START_TIME: "select_start_time",
        SELECT_SUPERPOWERS: "select_superpowers",
        SELECT_TYPE_OF_WORK_PREFERENCE: "select_type_of_work_preference",
        SHARE_LINKED_IN: "share_linked_in",
        SHARE_OTHER_WEBSITES: "share_other_websites",
        SIGN_UP: "sign_up",
        START_EDIT_PROFILE: "start_edit_profile",
        START_PROFILE_CREATION: "start_profile_creation",
        START_PROPOSAL: "start_proposal",
        START_REPLY: "start_reply",
        STEP_1_SELECTED: "step_1_selected",
        STEP_2_SELECTED: "step_2_selected",
        SUBMIT_PROPOSAL: "submit_proposal",
        TALENT_REFERRAL_COPY_LINK: "talent_referral_copy_link",
        TALENT_REFERRAL_EMAIL_LINK: "talent_referral_email_link",
        TALENT_REFERRAL_LINKEDIN_LINK: "talent_referral_linkedin_link",
        TALENT_REFERRAL_TWITTER_LINK: "talent_referral_twitter_link",
        UNAPPROVE_TALENT: "unapprove_talent",
        UNAUTHENTICATED_USER_JOB_APPLY_CLICKS: "unauthenticated_user_job_apply_clicks",
        USER_CLICKS: "user_clicks",
        VERIFIES_EMAIL: "verifies_email",
        VIEW_BOOST_BANNER: "view_boost_banner",
        VIEW_CLIENT_INVITE_DASHBOARD: "view_client_invite_dashboard",
        VIEW_CLIENT_INVITE_JOIN_PAGE: "view_client_invite_join_page",
        VIEW_CLIENT_INVITE_ORG_PAGE: "view_client_invite_org_page",
        VIEW_CLIENT_SIGNUP_EMAIL_PAGE: "view_client_signup_email_page",
        VIEW_CLIENT_SIGNUP_EXISTING_ERROR: "view_client_signup_existing_error",
        VIEW_CLIENT_SIGNUP_JOIN_PAGE: "view_client_signup_join_page",
        VIEW_CLIENT_SIGNUP_ORG_PAGE: "view_client_signup_org_page",
        VIEW_INSIGHTS_BANNER: "view_insights_banner",
        VIEW_INSIGHTS_SECTION: "view_insights_section",
        VIEW_INSIGHTS_TOOLTIP: "view_insights_tooltip",
        VIEW_JOB_CARD_EXPANDED_VIEW: "view_job_card_expanded_view",
        VIEW_JOB_CONFIRMATION: "view_job_confirmation",
        VIEW_JOB_DETAILS: "view_job_details",
        VIEW_POST: "view_post",
    },
    // This file is generated by script, can't be modified manually
    UploadType: {
        ARTICLE_FEATURE_IMAGE_UPLOAD: "article_feature_image_upload",
        BID_ATTACHMENT_UPLOAD_TYPE: "bid_attachment_upload_type",
        EMPLOYER_LOGO_IMAGE_UPLOAD: "employer_logo_image_upload",
        FREELANCER_COVER_PHOTO_IMAGE_UPLOAD: "freelancer_cover_photo_image_upload",
        FREELANCER_W8_ATTACHMENT_UPLOAD_TYPE: "freelancer_w8_attachment_upload_type",
        FREELANCER_W9_ATTACHMENT_UPLOAD_TYPE: "freelancer_w9_attachment_upload_type",
        INVOICE_ATTACHMENT_UPLOAD_TYPE: "invoice_attachment_upload_type",
        JOB_ATTACHMENT_UPLOAD_TYPE: "job_attachment_upload_type",
        JOB_DESCRIPTION_FILE_UPLOAD_TYPE: "job_description_file_upload_type",
        LINKEDIN_USER_DATA_CSV: "linkedin_user_data_csv",
        PORTFOLIO_ITEM_IMAGE_UPLOAD: "portfolio_item_image_upload",
        POST_ATTACHMENT_UPLOAD_TYPE: "post_attachment_upload_type",
        RESUME_ATTACHMENT_UPLOAD_TYPE: "resume_attachment_upload_type",
        ROOM_MESSAGE_ATTACHMENT_UPLOAD_TYPE: "room_message_attachment_upload_type",
        SPACE_AVATAR_UPLOAD: "space_avatar_upload",
        TOPIC_PREDICTION_UPLOAD_TYPE: "topic_prediction_upload_type",
        USER_AVATAR_IMAGE_UPLOAD: "user_avatar_image_upload",
    },
    // This file is generated by script, can't be modified manually
    WithdrawalContentType: {
        ExternalTokenWithdrawalMethod: "ExternalTokenWithdrawalMethod",
        StripeWithdrawalMethod: "StripeWithdrawalMethod",
        TransferWiseWithdrawalMethod: "TransferWiseWithdrawalMethod",
    },
    // This file is generated by script, can't be modified manually
    ATSDataSyncModel: {
        JOB: "job",
        CANDIDATE: "candidate",
        APPLICATION: "application",
        OFFER: "offer",
    },
    // This file is generated by script, can't be modified manually
    ATSIntegration: {
        APPLICANTSTACK: "ApplicantStack",
        ASHBY: "Ashby",
        BAMBOOHR: "BambooHR",
        BREEZY: "Breezy",
        BULLHORN: "Bullhorn",
        CATS: "CATS",
        CLAYHR: "ClayHR",
        CLOCKWORK: "Clockwork",
        COMEET: "Comeet",
        CORNERSTONE_TALENTLINK: "Cornerstone TalentLink",
        ENGAGEATS: "EngageATS",
        EPLOY: "Eploy",
        FOUNTAIN: "Fountain",
        FRESHTEAM: "Freshteam",
        GREENHOUSE: "Greenhouse",
        GREENHOUSE_JOB_BOARDS: "Greenhouse Job Boards API",
        HARBOUR_ATS: "Harbour ATS",
        HOMERUN: "Homerun",
        HR_CLOUD: "HR Cloud",
        ICIMS: "iCIMS",
        INFINITE_BRASSRING: "Infinite BrassRing",
        JAZZHR: "JazzHR",
        JOBADDER: "JobAdder",
        JOBSCORE: "JobScore",
        JOBSOID: "Jobsoid",
        JOBVITE: "Jobvite",
        LANO: "Lano",
        LEVER: "Lever",
        ORACLE_FUSION_RECRUITING_CLOUD: "Oracle Fusion Recruiting Cloud",
        ORACLE_TALEO: "Oracle Taleo",
        PERSONIO_RECRUITING: "Personio Recruiting",
        POLYMER: "Polymer",
        RECRUITEE: "Recruitee",
        RECRUITERFLOW: "RecruiterFlow",
        RECRUITIVE: "Recruitive",
        SAGE_HR: "Sage HR",
        SAP_SUCCESSFACTORS: "SAP SuccessFactors",
        SMARTRECRUITERS: "SmartRecruiters",
        TALENTLYFT: "TalentLyft",
        TALENTREEF: "TalentReef",
        TEAMTAILOR: "Teamtailor",
        TELLENT: "Tellent",
        UKG_PRO_RECRUITING: "UKG Pro Recruiting",
        WORKABLE: "Workable",
        WORKDAY: "Workday",
        ZOHO_RECRUIT: "Zoho Recruit",
    },
    // This file is generated by script, can't be modified manually
    ATSLinkedAccountIssueStatus: {
        ONGOING: "ONGOING",
        RESOLVED: "RESOLVED",
    },
    // This file is generated by script, can't be modified manually
    ATSModel: {
        JOBS: "jobs",
        CANDIDATES: "candidates",
        APPLICATIONS: "applications",
        OFFERS: "offers",
    },
    // This file is generated by script, can't be modified manually
    ATSModelLabels: {
        jobs: "jobs",
        candidates: "candidates",
        applications: "applications",
        offers: "offers",
    },

    AccentColors: {
        LIGHT_GREY: "--off-white",
        BEIGE: "--soft-beige",
        SOFT_RED: "--soft-red",
        SOFT_ORANGE: "--soft-orange",
        SOFT_YELLOW: "--soft-yellow",
        SOFT_GREEN: "--soft-green",
        SOFT_BLUE: "--soft-blue",
        SOFT_TEAL: "--soft-teal",
        COOL_GREY_1: "--soft-grey",
        COOL_GREY_2: "--medium-grey",
        SOFT_VIOLET: "--soft-violet",
    },
    // This file is generated by script, can't be modified manually
    AccentColorsLabels: {
        '--off-white': "Light Grey",
        '--soft-beige': "Beige",
        '--soft-red': "Soft Red",
        '--soft-orange': "Soft Orange",
        '--soft-yellow': "Soft Yellow",
        '--soft-green': "Soft Green",
        '--soft-blue': "Soft Blue",
        '--soft-teal': "Soft Teal",
        '--soft-grey': "Cool Grey 1",
        '--medium-grey': "Cool Grey 2",
        '--soft-violet': "Soft Violet",
    },

    AccessActions: {
        USER_LOGGED_IN: "user_logged_in",
        USER_LOGIN_FAILED: "user_login_failed",
        USER_REGISTERED: "user_registered",
    },
    // This file is generated by script, can't be modified manually
    AccountMode: {
        GET_A_JOB: "get_a_job",
        COMMUNITY: "community",
    },
    // This file is generated by script, can't be modified manually
    AccountModeLabels: {
        get_a_job: "Get a job",
        community: "Community",
    },

    AccountType: {
        FREELANCER: "freelancer",
        EMPLOYER: "employer",
    },
    // This file is generated by script, can't be modified manually
    AccountTypeLabels: {
        freelancer: "Freelancer",
        employer: "Employer",
    },

    AmountType: {
        PERCENT: "percent",
        FIXED: "fixed",
    },
    // This file is generated by script, can't be modified manually
    AmountTypeLabels: {
        percent: "Percent",
        fixed: "Fixed Amount",
    },

    ApplicationQuestionStatus: {
        ANSWERED: "answered",
        NOT_ANSWERED: "not_answered",
        NOT_AVAILABLE: "not_available",
    },
    // This file is generated by script, can't be modified manually
    ApplicationQuestionStatusLabels: {
        answered: "Answered",
        not_answered: "Not answered",
        not_available: "Not available",
    },

    AssignOwnersOptions: {
        NEW_JOBS: "new_jobs",
        OPEN_JOBS: "open_jobs",
        ACTIVE_JOBS: "active_jobs",
        COMPLETED_JOBS: "completed_jobs",
    },
    // This file is generated by script, can't be modified manually
    AssignOwnersOptionsLabels: {
        new_jobs: "New/Future Jobs",
        open_jobs: "Open jobs",
        active_jobs: "Active Jobs",
        completed_jobs: "Closed/Completed Jobs",
    },

    AuthFailedErrorCodes: {
        NO_ACCOUNT: "no_account",
        NO_SOCIAL_ACCOUNT: "no_social_account",
        AUTH_CANCELLED: "auth_cancelled",
        EXTRA_AUTH_REQUIRED: "extra_auth_required",
        ACCOUNT_ALREADY_EXISTS: "account_already_exists",
        ANOTHER_USER_LOGGED_IN: "another_user_logged_in",
        ACCOUNT_DISABLED: "account_disabled",
        LAST_NAME_HIDDEN: "last_name_hidden",
        LINKEDIN_SIGNUP_DISABLED: "linkedin_signup_disabled",
    },
    // This file is generated by script, can't be modified manually
    BatchFreelancerNewOpenJobsNotificationOptions: {
        DAILY: "DAILY",
        EVERY_OTHER_DAY: "EVERY_OTHER_DAY",
        WEEKLY: "WEEKLY",
        NEVER: "NEVER",
    },
    // This file is generated by script, can't be modified manually
    BatchFreelancerNewOpenJobsNotificationOptionsLabels: {
        DAILY: "Daily",
        EVERY_OTHER_DAY: "Every other day",
        WEEKLY: "Weekly",
        NEVER: "Never",
    },

    BidNotHiringReasonType: {
        PRICE: "price",
        SKILLS: "not_the_right_skills",
        SENIORITY: "not_senior_enough",
        TIMEZONE: "timezone",
        UNRESPONSIVE: "missed_screening_unresponsive",
        LANGUAGE: "language_barrier",
        OTHER: "other",
    },
    // This file is generated by script, can't be modified manually
    BidNotHiringReasonTypeLabels: {
        price: "Price",
        not_the_right_skills: "Not the right skills",
        not_senior_enough: "Not senior enough",
        timezone: "Time zone",
        missed_screening_unresponsive: "Missed screening / unresponsive",
        language_barrier: "Language barrier",
        other: "Other",
    },

    BidReactionType: {
        THUMBS_UP: "thumbs_up",
        THINKING: "thinking",
    },
    // This file is generated by script, can't be modified manually
    BidReactionTypeLabels: {
        thumbs_up: "Thumbs Up",
        thinking: "Thinking",
    },

    BidReportEnglishLevel: {
        LEVEL_6: "6",
        LEVEL_5: "5",
        LEVEL_4: "4",
        LEVEL_3: "3",
        LEVEL_2: "2",
        LEVEL_1: "1",
        LEVEL_UNKNOWN: "unknown",
        LEVEL_0: "0",
    },
    // This file is generated by script, can't be modified manually
    BidReportEnglishLevelLabels: {
        6: "Native speaker",
        5: "Near native/fluent",
        4: "Excellent command/highly proficient in spoken and written",
        3: "Very good command",
        2: "Good command/good working knowledge",
        1: "Basic communication skills/working knowledge",
        unknown: "Unknown level",
        0: "English not added",
    },

    BidStatus: {
        NEW: "new",
        APPLICATION_CANCELED: "application_canceled",
        VIEWED: "viewed",
        INTERVIEWING: "interviewing",
        REJECTED: "rejected",
        APPLICATION_IN_REVIEW: "application_in_review",
        IN_REVIEW_AFTER_AI_INTERVIEW: "in_review_after_ai_interview",
        LIVE_INTERVIEW_AFTER_AI_INTERVIEW: "live_interview_after_ai_interview",
        OFFER_MADE: "offer_made",
        OFFER_UPDATED: "offer_updated",
        OFFER_DECLINED: "offer_declined",
        OFFER_CANCELED: "offer_canceled",
        OFFER_EXPIRED: "offer_expired",
        OFFER_WAITING_FOR_DEPOSIT: "offer_waiting_for_deposit",
        OFFER_DEPOSIT_PAYMENT_FAILED: "offer_deposit_payment_failed",
        OFFER_DEPOSIT_PAYMENT_IN_PROGRESS: "offer_deposit_payment_in_progress",
        HIRED: "hired",
    },
    // This file is generated by script, can't be modified manually
    BidStatusLabels: {
        new: "New",
        application_canceled: "Application canceled",
        viewed: "Viewed",
        interviewing: "Interviewing",
        rejected: "Rejected",
        application_in_review: "Application in review",
        in_review_after_ai_interview: "In Review after AI review",
        live_interview_after_ai_interview: "Interviewing",
        offer_made: "Offer made",
        offer_updated: "Offer updated",
        offer_declined: "Offer declined",
        offer_canceled: "Offer canceled",
        offer_expired: "Offer expired",
        offer_waiting_for_deposit: "Waiting for deposit",
        offer_deposit_payment_failed: "Deposit has failed",
        offer_deposit_payment_in_progress: "Deposit in progress",
        hired: "Hired",
    },

    BroadcastType: {
        PONG: "PONG",
        SITE_NOTIFICATION: "SITE_NOTIFICATION",
        MESSAGE: "MESSAGE",
        MESSAGE_STATUS: "MESSAGE_STATUS",
        USER_LOGGED_OUT: "USER_LOGGED_OUT",
        FILE_UPLOAD_STATUS: "FILE_UPLOAD_STATUS",
        FREELANCER_APPROVAL: "FREELANCER_APPROVAL",
        MESSAGE_MARK_AS_READ: "MESSAGE_MARK_AS_READ",
        MESSAGE_SPAM_STATUS: "MESSAGE_SPAM_STATUS",
        USER_POST_BAN: "USER_POST_BAN",
        PRIVACY_SETTINGS_CHANGED: "PRIVACY_SETTINGS_CHANGED",
        POST_ADDED: "POST_ADDED",
        POST_UPDATED: "POST_UPDATED",
        POST_DELETED: "POST_DELETED",
        POST_MOVED_TO_SPACE: "POST_MOVED_TO_SPACE",
        REACTION_ADDED: "REACTION_ADDED",
        REACTION_DELETED: "REACTION_DELETED",
        VOTE_ADDED: "VOTE_ADDED",
        YOU_VOTED: "YOU_VOTED",
        HELP_OFFER_UPDATED: "HELP_OFFER_UPDATED",
        CALENDAR_LINK_PROMPT_SHOWN: "calendar_link_prompt_shown",
        USER_VERIFY_ACCOUNT: "USER_VERIFY_ACCOUNT",
        CAN_VIEW_EMPLOYER_INVOICES: "CAN_VIEW_EMPLOYER_INVOICES",
        SPACE_POST_ADDED: "SPACE_POST_ADDED",
        USER_ADDED_TO_SPACE: "USER_ADDED_TO_SPACE",
        BATCHED_POST_OR_COMMENT_EVENTS: "BATCHED_POST_OR_COMMENT_EVENTS",
        CURRENT_OFFER_DEPOSIT_STATUS_CHANGED: "CURRENT_OFFER_DEPOSIT_STATUS_CHANGED",
    },
    // This file is generated by script, can't be modified manually
    CaptchaActions: {
        SIGN_UP: "sign_up",
        LOGIN: "login",
        SEND_TICKET: "send_ticket",
        INVITE_EMPLOYER: "invite_employer",
        RESEND_EMPLOYER_INVITATION: "resend_employer_invitation",
        PHONE_VERIFICATION: "phone_verification",
        PASSWORD_RESET: "password_reset",
        VERIFICATION_EMAIL_RESEND: "email_send",
        ANON_VERIFICATION_EMAIL_RESEND: "anon_email_send",
    },
    // This file is generated by script, can't be modified manually
    CaptchaErrorKey: {
        CAPTCHA: "captcha",
    },
    // This file is generated by script, can't be modified manually
    CaptchaErrorValue: {
        REQUIRED: "required",
    },
    // This file is generated by script, can't be modified manually
    CareerHelpHighlightsNotificationOptions: {
        DAILY: "DAILY",
        WEEKLY: "WEEKLY",
        NEVER: "NEVER",
    },
    // This file is generated by script, can't be modified manually
    CareerHelpHighlightsNotificationOptionsLabels: {
        DAILY: "Daily",
        WEEKLY: "Weekly",
        NEVER: "Never",
    },

    CareerHelpNotificationOptions: {
        INSTANTLY: "INSTANTLY",
        DAILY: "DAILY",
        WEEKLY: "WEEKLY",
        NEVER: "NEVER",
    },
    // This file is generated by script, can't be modified manually
    CareerHelpNotificationOptionsLabels: {
        INSTANTLY: "Instantly",
        DAILY: "Daily",
        WEEKLY: "Weekly",
        NEVER: "Never",
    },

    ClickViewEventType: {
        JOB_SHORT_CLICKED: "job_short_clicked",
        JOB_SHORT_VIEWED: "job_short_viewed",
        JOB_DETAIL_VIEWED: "job_detail_viewed",
        JOB_APPLY_CLICKED: "job_apply_clicked",
        TALENT_SHORT_VIEWED: "talent_short_viewed",
        TALENT_SHORT_CLICKED: "talent_short_clicked",
        SEARCH_FILTER_SEARCH: "filter_search",
        SEARCH_CLICK_VIEW_JOB: "click_view_job_search",
        SEARCH_CLICK_JOB_TITLE: "click_job_title_search",
        SEARCH_CLICK_EXPAND_DETAILS: "click_expand_details_search",
        SEARCH_CLICK_APPLY: "click_apply_search",
        SEARCH_CLICK_TALENT: "click_talent_search",
        SEARCH_CLICK_TALENT_INVITE: "click_talent_invite_search",
    },
    // This file is generated by script, can't be modified manually
    ComponentType: {
        ADDITION: "+",
        DEDUCTION: "-",
    },
    // This file is generated by script, can't be modified manually
    ComponentTypeLabels: {
        '+': "Addition (+)",
        '-': "Deduction (-)",
    },

    ConfirmationServiceError: {
        KEY_INVALID_OR_EXPIRED: "key_invalid_or_expired",
        OFFER_INVALID: "offer_invalid",
        USER_INVALID: "user_invalid",
        USER_NOT_INTENDED: "user_not_intended",
    },
    // This file is generated by script, can't be modified manually
    ConfirmationServiceErrorLabels: {
        key_invalid_or_expired: "Invalid key or key signature expired.",
        offer_invalid: "Invalid offer.",
        user_invalid: "Invalid user.",
        user_not_intended: "This link is intended for another user.",
    },

    Currency: {
        USD: "USD",
        TOKEN: "BTRST",
    },
    // This file is generated by script, can't be modified manually
    EmailValidationError: {
        REQUIRED: "required",
        INVALID: "invalid",
        RESTRICTED: "restricted",
        MAX_LENGTH: "max_length",
    },
    // This file is generated by script, can't be modified manually
    EmployerLogoBackgroundColor: {
        SOFT_BEIGE: "--soft-beige",
        OFF_WHITE: "--off-white",
        MEDIUM_RED: "--medium-red",
        SOFT_RED: "--soft-red",
        MEDIUM_ORANGE: "--medium-orange",
        SOFT_ORANGE: "--soft-orange",
        MEDIUM_GREEN: "--medium-green",
        SOFT_GREEN: "--soft-green",
        MEDIUM_TEAL: "--medium-teal",
        MEDIUM_VIOLET: "--medium-violet",
        SOFT_VIOLET: "--soft-violet",
        MEDIUM_BLUE: "--medium-blue",
        SOFT_BLUE: "--soft-blue",
        YELLOW: "--yellow",
        SOFT_YELLOW: "--soft-yellow",
        SOFT_GREY: "--soft-grey",
        WHITE: "--white",
    },
    // This file is generated by script, can't be modified manually
    EmployerNameColor: {
        DARK_BEIGE: "--dark-beige",
        DARK_RED: "--dark-red",
        DARK_ORANGE: "--dark-orange",
        DARK_GREEN: "--dark-green",
        DARK_TEAL: "--dark-teal",
        DARK_VIOLET: "--dark-violet",
        DARK_BLUE: "--dark-blue",
        DARK_YELLOW: "--dark-yellow",
        DARK_GREY: "--dark-grey",
    },
    // This file is generated by script, can't be modified manually
    EmployerSize: {
        SMB: "SMB",
        MID_MARKET: "MidMarket",
        ENTERPRISE: "Enterprise",
    },
    // This file is generated by script, can't be modified manually
    EmployerSizeLabels: {
        SMB: "SMB",
        MidMarket: "MidMarket",
        Enterprise: "Enterprise",
    },

    ExperienceLevel: {
        LESS_THREE_YEARS: "less_three_years",
        THREE_FIVE_YEARS: "three_five_years",
        FIVE_EIGHT_YEARS: "five_eight_years",
        EIGHT_OR_MORE_YEARS: "eight_or_more_years",
    },
    // This file is generated by script, can't be modified manually
    ExperienceLevelLabels: {
        less_three_years: "Less than 3 years",
        three_five_years: "3-5 years",
        five_eight_years: "5-8 years",
        eight_or_more_years: "8 or more years",
    },

    ExternalTokenWithdrawalRejectReason: {
        TOKEN_WITHDRAWALS_DISABLED: "token_withdrawals_disabled",
        EMPLOYER_ACCOUNT: "employer_account",
        ANOTHER_TRANSACTION_IN_PROGRESS: "another_transaction_in_progress",
        NO_FUNDS: "no_funds",
        LIMIT_EXCEEDED: "limit_exceeded",
        SAFE_WALLET_BEING_CREATED: "safe_wallet_being_created",
    },
    // This file is generated by script, can't be modified manually
    ExternalTokenWithdrawalRejectReasonLabels: {
        token_withdrawals_disabled: "Withdrawals of BTRST tokens will be supported when the Braintrust network publicly launches.",
        employer_account: "Withdrawals for Employers are disabled.",
        another_transaction_in_progress: "Only one transaction can be processed at a time.",
        no_funds: "No sufficient funds.",
        limit_exceeded: "Withdrawals limit exceeded.",
        safe_wallet_being_created: "Your safe wallet is being created. Withdrawals will be possible once this process finishes",
    },

    FileExtension: {
        CSV: ".csv",
        DOCX: ".docx",
        HTML: ".html",
        JPEG: ".jpeg",
        JSON: ".json",
        PDF: ".pdf",
        PNG: ".png",
        TXT: ".txt",
        XML: ".xml",
        ZIP: ".zip",
    },
    // This file is generated by script, can't be modified manually
    FileFormat: {
        CSV: "csv",
        DOCX: "docx",
        HTML: "html",
        JPEG: "jpeg",
        JSON: "json",
        PDF: "pdf",
        PNG: "png",
        TXT: "txt",
        XML: "xml",
        ZIP: "zip",
    },
    // This file is generated by script, can't be modified manually
    FreelancerApprovalStatus: {
        APPROVED: "Approved",
        PENDING: "Pending",
        NOT_APPROVED: "Not approved",
    },
    // This file is generated by script, can't be modified manually
    FreelancerAvailability: {
        IMMEDIATELY: "immediately",
        TWO_WEEKS_FROM_OFFER: "two_weeks_from_offer",
        SPECIFIC_DATE: "specific_date",
    },
    // This file is generated by script, can't be modified manually
    FreelancerAvailabilityLabels: {
        immediately: "Immediately",
        two_weeks_from_offer: "2 weeks from offer",
        specific_date: "Specific date",
    },

    FreelancerAvailabilityForWork: {
        FULL_TIME: "full_time",
        PART_TIME: "part_time",
        DIRECT_HIRE: "direct_hire",
    },
    // This file is generated by script, can't be modified manually
    FreelancerAvailabilityForWorkLabels: {
        full_time: "Full time roles",
        part_time: "Part time roles",
        direct_hire: "Employee (direct hire) roles",
    },

    FreelancerBidFilterStatus: {
        ALL: "all",
        TOP_MATCHES: "top_matches",
        APPROVED: "approved",
        NOT_APPROVED: "not_approved",
        INVITED: "invited",
    },
    // This file is generated by script, can't be modified manually
    FreelancerBidFilterStatusLabels: {
        all: "All Talent",
        top_matches: "Top matches only",
        approved: "Approved Talent",
        not_approved: "Not approved Talent",
        invited: "Invited Talent",
    },

    FreelancerProfileVisibility: {
        PUBLIC: "public",
        PRIVATE: "private",
    },
    // This file is generated by script, can't be modified manually
    FreelancerProfileVisibilityLabels: {
        public: "Public",
        private: "Private",
    },

    HelpOfferDirectionFilter: {
        SENT: "sent",
        RECEIVED: "received",
    },
    // This file is generated by script, can't be modified manually
    HelpOfferMessageType: {
        HELP_OFFER_CREATED: "help_offer_created",
        HELP_OFFER_ACCEPTED: "help_offer_accepted",
        HELP_OFFER_DECLINED: "help_offer_declined",
        HELP_OFFER_DELETED: "help_offer_deleted",
        HELP_OFFER_COMPLETED: "help_offer_completed",
        HELP_OFFER_EXPIRED: "help_offer_expired",
        HELP_OFFER_REPORTED: "help_offer_reported",
        HELP_OFFER_BUDGET_CHANGED: "help_offer_budget_changed",
        HELP_OFFER_REFUND_REQUESTED: "help_offer_refund_requested",
        HELP_OFFER_REFUND_ISSUED: "help_offer_refund_issued",
        HELP_OFFER_REFUND_REQUEST_CANCELED: "help_offer_refund_requested_canceled",
        HELP_OFFER_MARKED_COMPLETE: "help_offer_marked_complete",
        HELP_OFFER_REVISION_REQUESTED: "help_offer_revision_requested",
        HELP_OFFER_REVIEW_SENT: "help_offer_review_sent",
    },
    // This file is generated by script, can't be modified manually
    HelpOfferReportReasons: {
        NO_REFUND: "Didn’t receive refund",
        UNRESPONSIVE: "Unresponsive",
    },
    // This file is generated by script, can't be modified manually
    HelpOfferStatus: {
        CREATED: "CREATED",
        PENDING: "PENDING",
        ACCEPTED: "ACCEPTED",
        DECLINED: "DECLINED",
        EXPIRED: "EXPIRED",
        DELETED: "DELETED",
        COMPLETED: "COMPLETED",
        PENDING_APPROVAL: "PENDING_APPROVAL",
        REFUND_REQUESTED: "REFUND_REQUESTED",
    },
    // This file is generated by script, can't be modified manually
    HelpOfferStatusLabels: {
        CREATED: "Created",
        PENDING: "Pending",
        ACCEPTED: "Active",
        DECLINED: "Declined",
        EXPIRED: "Expired",
        DELETED: "Cancelled",
        COMPLETED: "Completed",
        PENDING_APPROVAL: "Pending approval",
        REFUND_REQUESTED: "Refund requested",
    },

    HelpOfferStatusFilter: {
        CREATED: "CREATED",
        PENDING: "PENDING",
        ACCEPTED: "ACCEPTED",
        DECLINED: "DECLINED",
        EXPIRED: "EXPIRED",
        DELETED: "DELETED",
        COMPLETED: "COMPLETED",
        PENDING_APPROVAL: "PENDING_APPROVAL",
        REFUND_REQUESTED: "REFUND_REQUESTED",
        REPORTED: "REPORTED",
    },
    // This file is generated by script, can't be modified manually
    HelpOfferStatusFilterLabels: {
        CREATED: "Created",
        PENDING: "Pending",
        ACCEPTED: "Active",
        DECLINED: "Declined",
        EXPIRED: "Expired",
        DELETED: "Cancelled",
        COMPLETED: "Completed",
        PENDING_APPROVAL: "Pending approval",
        REFUND_REQUESTED: "Refund requested",
        REPORTED: "Reported",
    },

    HourOverlap: {
        FULL_WORK_DAY: "full_work_day",
        FEW_HOURS: "few_hours",
    },
    // This file is generated by script, can't be modified manually
    Interest: {
        DESIGN: "design",
        ENGINEERING: "engineering",
        PRODUCT: "product",
        WEB3: "web3",
        FREELANCING: "freelancing",
        CAREER_ADVICE: "career_advice",
        MENTORSHIP: "mentorship",
        MENTORING: "mentoring",
    },
    // This file is generated by script, can't be modified manually
    InterestLabels: {
        design: "Design",
        engineering: "Engineering",
        product: "Product",
        web3: "Web3",
        freelancing: "Freelancing",
        career_advice: "Career advice",
        mentorship: "Mentorship",
        mentoring: "Mentoring",
    },

    InternalTokenTransferStatus: {
        PENDING: "pending",
        SUCCESS: "success",
        FAILED: "failed",
        AWAITING_WALLET_CREATION: "awaiting_wallet_creation",
    },
    // This file is generated by script, can't be modified manually
    InterviewRatingStatus: {
        FAIL: "fail",
        PASS: "pass",
        MIXED: "mixed",
    },
    // This file is generated by script, can't be modified manually
    InvitationType: {
        EMPLOYER_INVITATION: "employer_invitation",
        JOB_INVITATION: "job_invitation",
        NEW_EMPLOYER_INVITATION: "new_employer_invitation",
        SPACE_INVITATION: "space_invitation",
    },
    // This file is generated by script, can't be modified manually
    InvoiceActionType: {
        ADMIN_MARK_AS_UNPAID: "admin_mark_as_unpaid",
        EMPLOYER_MARK_AS_PAID: "employer_mark_as_paid",
        FREELANCER_MARK_AS_PAID: "freelancer_mark_as_paid",
        PAY_TALENT_BY_NETD: "pay_talent_by_netd",
        MARK_AS_PAID_OFF_PLATFORM: "mark_as_paid_off_platform",
        MARK_INVOICE_AS_PAID_DO_NOT_INITIATE_PAYMENT: "mark_invoice_as_paid_do_not_initiate_payment",
        MARK_NETD_AS_RESOLVED: "mark_netd_as_resolved",
        MARK_NETD_AS_UNRESOLVED: "mark_netd_as_unresolved",
        MARK_AS_WRITTEN_OFF: "mark_as_written_off",
        SETTLE_EMPLOYER_PAYMENTS: "settle_employer_payments",
        SET_BRAINTRUST_FEE_INVOICE_AS_PAID: "set_braintrust_fee_invoice_as_paid",
        CANCEL: "cancel",
        UNCANCEL: "uncancel",
        BLOCK_PAYMENT_FOR_EDITING: "block_payment_for_editing",
        EDITED: "edited",
        PO_NUMBER_CHANGED: "po_number_changed",
    },
    // This file is generated by script, can't be modified manually
    InvoiceActionTypeLabels: {
        admin_mark_as_unpaid: "Admin Marked As Unpaid",
        employer_mark_as_paid: "Employer Marked As Paid",
        freelancer_mark_as_paid: "Freelancer Marked As Paid",
        pay_talent_by_netd: "Client has not paid, but initiate payment to talent (Net30)",
        mark_as_paid_off_platform: "Mark invoice as paid off platform and initiate payment to talent",
        mark_invoice_as_paid_do_not_initiate_payment: "Mark Invoice as paid, do not initiate payment",
        mark_netd_as_resolved: "Mark Net30 Invoice as resolved",
        mark_netd_as_unresolved: "Mark Net30 Invoice as unresolved",
        mark_as_written_off: "Mark invoice as written-off",
        settle_employer_payments: "Settle employer payments",
        set_braintrust_fee_invoice_as_paid: "Set Braintrust fee invoice as paid",
        cancel: "Cancel",
        uncancel: "Uncancel",
        block_payment_for_editing: "Invoice marked as blocked for the time of editing the invoice",
        edited: "Invoice has been edited",
        po_number_changed: "PO number has been changed",
    },

    InvoiceFeeType: {
        BACKGROUND_FEE: "BackgroundFee",
        BRAINTRUST_DIRECT_HIRE_FEE: "BraintrustDirectHireFee",
        BRAINTRUST_FEE: "BraintrustFee",
        CC_FEE: "CCFee",
        COMPLIANCE_FEE: "ComplianceFee",
        FREE_FORM_FEE: "FreeFormFee",
        MSP_FEE: "MSPFee",
        PAYMENT_TERMS_FEE: "PaymentTermsFee",
        W2_FEE: "W2Fee",
    },
    // This file is generated by script, can't be modified manually
    InvoiceHistoryType: {
        ADMIN_MARK_AS_UNPAID: "admin_mark_as_unpaid",
        EMPLOYER_MARK_AS_PAID: "employer_mark_as_paid",
        FREELANCER_MARK_AS_PAID: "freelancer_mark_as_paid",
        PAY_TALENT_BY_NETD: "pay_talent_by_netd",
        MARK_AS_PAID_OFF_PLATFORM: "mark_as_paid_off_platform",
        MARK_INVOICE_AS_PAID_DO_NOT_INITIATE_PAYMENT: "mark_invoice_as_paid_do_not_initiate_payment",
        MARK_NETD_AS_RESOLVED: "mark_netd_as_resolved",
        MARK_NETD_AS_UNRESOLVED: "mark_netd_as_unresolved",
        MARK_AS_WRITTEN_OFF: "mark_as_written_off",
        SETTLE_EMPLOYER_PAYMENTS: "settle_employer_payments",
        SET_BRAINTRUST_FEE_INVOICE_AS_PAID: "set_braintrust_fee_invoice_as_paid",
        CANCEL: "cancel",
        UNCANCEL: "uncancel",
        BLOCK_PAYMENT_FOR_EDITING: "block_payment_for_editing",
        EDITED: "edited",
        PO_NUMBER_CHANGED: "po_number_changed",
        WITHDRAWAL_TRANSACTION: "withdrawal_transaction",
        PAYMENT_TRANSACTION: "payment_transaction",
        ACCELERATED_PAYOUT: "accelerated_payout",
    },
    // This file is generated by script, can't be modified manually
    InvoiceHistoryTypeLabels: {
        admin_mark_as_unpaid: "Admin Marked As Unpaid",
        employer_mark_as_paid: "Employer Marked As Paid",
        freelancer_mark_as_paid: "Freelancer Marked As Paid",
        pay_talent_by_netd: "Client has not paid, but initiate payment to talent (Net30)",
        mark_as_paid_off_platform: "Mark invoice as paid off platform and initiate payment to talent",
        mark_invoice_as_paid_do_not_initiate_payment: "Mark Invoice as paid, do not initiate payment",
        mark_netd_as_resolved: "Mark Net30 Invoice as resolved",
        mark_netd_as_unresolved: "Mark Net30 Invoice as unresolved",
        mark_as_written_off: "Mark invoice as written-off",
        settle_employer_payments: "Settle employer payments",
        set_braintrust_fee_invoice_as_paid: "Set Braintrust fee invoice as paid",
        cancel: "Cancel",
        uncancel: "Uncancel",
        block_payment_for_editing: "Invoice marked as blocked for the time of editing the invoice",
        edited: "Invoice has been edited",
        po_number_changed: "PO number has been changed",
        withdrawal_transaction: "Withdrawal Transaction",
        payment_transaction: "Payment Transaction",
        accelerated_payout: "Accelerated Talent Payout",
    },

    InvoiceNumberError: {
        DUPLICATED_NUMBER_ERROR: "DUPLICATED_NUMBER_ERROR",
    },
    // This file is generated by script, can't be modified manually
    InvoiceStatus: {
        OPEN: "open",
        NEED_PAYMENT_APPROVAL_FROM_FREELANCER: "need_payment_approval_from_freelancer",
        NEED_OFF_PLATFORM_PAYMENT_FOR_FREELANCER: "need_off_platform_payment_for_freelancer",
        PROCESSING_PAYMENT: "processing_payment",
        PAID: "paid",
        OVERDUE: "overdue",
        CANCELLED: "cancelled",
        WRITTEN_OFF: "written_off",
    },
    // This file is generated by script, can't be modified manually
    InvoiceStatusLabels: {
        open: "Open",
        need_payment_approval_from_freelancer: "Need payment approval from talent",
        need_off_platform_payment_for_freelancer: "Need off platform payment for talent",
        processing_payment: "Processing Payment",
        paid: "Paid",
        overdue: "Overdue",
        cancelled: "Cancelled",
        written_off: "Written Off",
    },

    InvoiceType: {
        UPGRADE_PURCHASE: "UpgradePurchase",
    },
    // This file is generated by script, can't be modified manually
    InvoicingFrequency: {
        MONTHLY: "monthly",
        WEEKLY_UNPAID: "weekly_unpaid",
        WEEKLY_SUBMITTED_UNPAID: "weekly_submitted_unpaid",
    },
    // This file is generated by script, can't be modified manually
    InvoicingFrequencyLabels: {
        monthly: "Monthly",
        weekly_unpaid: "Weekly unpaid",
        weekly_submitted_unpaid: "Weekly submitted unpaid",
    },

    IsActiveStatus: {
        ACTIVE: "active",
        INACTIVE: "inactive",
    },
    // This file is generated by script, can't be modified manually
    IsActiveStatusLabels: {
        active: "Active",
        inactive: "Inactive",
    },

    JobActionStates: {
        ACTIVE: "active",
        DISABLED: "disabled",
    },
    // This file is generated by script, can't be modified manually
    JobActions: {
        EDIT_JOB: "edit_job",
        REVIEW_CANDIDATES: "review_candidate_list",
        INVITE_CANDIDATE: "invite_candidate_to_apply",
        CHANGE_OWNER: "change_job_owner",
        ADD_TEAM_MEMBERS: "add_team_members",
        SUBSCRIBE_TO_JOB: "subscribe_to_job",
        UNSUBSCRIBE_FROM_JOB: "unsubscribe_from_job",
        PUT_ON_HOLD: "put_on_hold",
        RELEASE_FROM_HOLD: "release_from_hold",
        COMPLETE_JOB: "complete_job",
        CREATE_INVOICE: "create_invoice",
        PAY_INVOICES: "pay_invoices",
        PAY_OVERDUE_INVOICES: "pay_overdue_invoices",
        CLOSE_INVOICING: "close_invoicing",
        OPEN_INVOICING: "open_invoicing",
        DUPLICATE_JOB_POST: "duplicate_job_post",
        RESET_JOB_POST_DATE: "reset_job_post_date",
        EDIT_SUPPORT_TYPE: "edit_support_type",
        CHANGE_OWNERS: "change_owners",
        JOB_EVENTS: "job_events",
        NOTES: "notes",
        SHARE_JOB: "share_job",
    },
    // This file is generated by script, can't be modified manually
    JobActionsLabels: {
        edit_job: "Edit job",
        review_candidate_list: "Review candidate list",
        invite_candidate_to_apply: "Invite candidate to apply",
        change_job_owner: "Change job owner",
        add_team_members: "Add team members",
        subscribe_to_job: "Subscribe to job",
        unsubscribe_from_job: "Unsubscribe from job",
        put_on_hold: "Pause job",
        release_from_hold: "Unpause job",
        complete_job: "Close job",
        create_invoice: "Create invoice",
        pay_invoices: "Pay invoices",
        pay_overdue_invoices: "Pay overdue invoices",
        close_invoicing: "Close invoicing",
        open_invoicing: "Open invoicing",
        duplicate_job_post: "Duplicate job post",
        reset_job_post_date: "Reset job post date",
        edit_support_type: "Edit support type",
        change_owners: "Change owners",
        job_events: "Job events",
        notes: "Notes",
        share_job: "Share job",
    },

    JobContractType: {
        SHORT: "short",
        LONG: "long",
        UNKNOWN: "unknown",
    },
    // This file is generated by script, can't be modified manually
    JobContractTypeLabels: {
        short: "Short term",
        long: "Long term",
        unknown: "Not sure",
    },

    JobCustomLocationType: {
        UNITED_STATES_ONLY: "united_states_only",
        NORTH_AMERICA: "north_america",
        SOUTH_AMERICA: "south_america",
        EUROPE: "europe",
        AFRICA: "africa",
        ASIA: "asia",
        OCEANIA: "oceania",
    },
    // This file is generated by script, can't be modified manually
    JobCustomLocationTypeLabels: {
        united_states_only: "United States only",
        north_america: "North America",
        south_america: "South America",
        europe: "Europe",
        africa: "Africa",
        asia: "Asia",
        oceania: "Oceania",
    },

    JobExperienceLevel: {
        LESS_THREE_YEARS: "less_three_years",
        THREE_FIVE_YEARS: "three_five_years",
        FIVE_TEN_YEARS: "five_ten_years",
        TEN_OR_MORE_YEARS: "ten_or_more_years",
    },
    // This file is generated by script, can't be modified manually
    JobExperienceLevelLabels: {
        less_three_years: "1-3 yrs",
        three_five_years: "3-5 yrs",
        five_ten_years: "5-10 yrs",
        ten_or_more_years: "10+ yrs",
    },

    JobFillSupportType: {
        MATCHER_ASSIGNED: "matcher_assigned",
        SELF_SERVICE: "self_service",
        BYOT: "byot",
    },
    // This file is generated by script, can't be modified manually
    JobFillSupportTypeLabels: {
        matcher_assigned: "Matcher assigned",
        self_service: "Self-service",
        byot: "BYOT",
    },

    JobFilledReasonType: {
        INTERNALLY: "internally",
        RECRUITING_FIRM: "recruiting_firm",
        ANOTHER_PLATFORM: "another_platform",
        OTHER: "other",
    },
    // This file is generated by script, can't be modified manually
    JobFilledReasonTypeLabels: {
        internally: "Internally",
        recruiting_firm: "From a recruiting firm",
        another_platform: "From another platform",
        other: "Other",
    },

    JobFilledUsingPlatformType: {
        INDEED: "indeed",
        UPWORK: "upwork",
        TOPTAL: "toptal",
        TURING: "turing",
        OTHER: "other",
    },
    // This file is generated by script, can't be modified manually
    JobFilledUsingPlatformTypeLabels: {
        indeed: "Indeed",
        upwork: "Upwork",
        toptal: "Toptal",
        turing: "Turing",
        other: "Other",
    },

    JobFreelancerStatus: {
        ACCEPTING_APPLICATIONS: "accepting_applications",
        APPLICATION_REJECTED: "application_rejected",
        APPLICATION_SENT: "application_sent",
        IN_REVIEW: "in_review",
        IN_REVIEW_AFTER_AI_INTERVIEW: "in_review_after_ai_interview",
        AI_INTERVIEWING: "ai_interviewing",
        LIVE_INTERVIEWING: "live_interviewing",
        INVITED_BY_CLIENT: "invited_by_client",
        INVITED_BY_MATCHER: "invited_by_matcher",
        JOB_CLOSED: "closed",
        JOB_FILLED: "job_filled",
        JOB_ON_HOLD: "on_hold",
        NEW_OFFER: "new_offer",
        OFFER_ACCEPTED: "offer_accepted",
        PENDING_APPROVAL: "pending_approval",
        UPDATED_OFFER: "updated_offer",
        OFFER_EXTENDED: "offer_extended",
    },
    // This file is generated by script, can't be modified manually
    JobFreelancerStatusLabels: {
        accepting_applications: "Accepting applications",
        application_rejected: "Not selected",
        application_sent: "Application sent",
        in_review: "In review",
        in_review_after_ai_interview: "In review",
        ai_interviewing: "Invited to interview",
        live_interviewing: "Invited to interview",
        invited_by_client: "Invited by client",
        invited_by_matcher: "Invited by matcher",
        closed: "Closed",
        job_filled: "Job filled",
        on_hold: "On hold",
        new_offer: "New offer",
        offer_accepted: "You’re hired 🎉",
        pending_approval: "Pending approval",
        updated_offer: "New offer",
        offer_extended: "Contract updated",
    },

    JobInterviewStatus: {
        ROUND_1: "round_1",
        ROUND_2: "round_2",
    },
    // This file is generated by script, can't be modified manually
    JobInvoiceFrequencyType: {
        WEEKLY: "weekly",
        BIWEEKLY: "biweekly",
        MONTHLY: "monthly",
        DECIDE_LATER: "decide_later",
    },
    // This file is generated by script, can't be modified manually
    JobInvoiceFrequencyTypeLabels: {
        weekly: "Weekly",
        biweekly: "Biweekly",
        monthly: "Monthly",
        decide_later: "I'll decide later",
    },

    JobLocationType: {
        GOOGLE: "google",
        CUSTOM: "custom",
    },
    // This file is generated by script, can't be modified manually
    JobLocationTypeLabels: {
        google: "Google",
        custom: "Custom",
    },

    JobNotFilledReasonType: {
        NO_QUALIFIED_CANDIDATES: "no_qualified_candidates",
        LOST_FUNDING: "lost_funding",
        REQUIREMENTS_CHANGE: "requirements_change",
        TIMELINE_CHANGE: "timeline_change",
        POSTED_IN_ERROR: "posted_in_error",
        HIRED_ELSEWHERE: "hired_elsewhere",
        OTHER: "other",
    },
    // This file is generated by script, can't be modified manually
    JobNotFilledReasonTypeLabels: {
        no_qualified_candidates: "No qualified candidates",
        lost_funding: "Lost funding",
        requirements_change: "Change in requirements",
        timeline_change: "Change in timeline",
        posted_in_error: "Posted in error",
        hired_elsewhere: "Hired Internally/By Competitor",
        other: "Other",
    },

    JobNotFoundErrorCode: {
        JOB_NOT_FOUND: "JOB_NOT_FOUND",
    },
    // This file is generated by script, can't be modified manually
    JobPaymentTerms: {
        NET15: "net15",
        NET30: "net30",
        OTHER: "other",
    },
    // This file is generated by script, can't be modified manually
    JobPaymentTermsLabels: {
        net15: "Net 15",
        net30: "Net 30",
        other: "Other",
    },

    JobPaymentType: {
        ANNUAL: "annual",
        FIXED_PRICE: "fixed_price",
        HOURLY: "hourly",
    },
    // This file is generated by script, can't be modified manually
    JobPaymentTypeLabels: {
        annual: "Annual",
        fixed_price: "Fixed Price",
        hourly: "Hourly",
    },

    JobSimpleStatus: {
        OPEN: "open",
        ACTIVE: "active",
        COMPLETED: "completed",
        ON_HOLD: "on_hold",
    },
    // This file is generated by script, can't be modified manually
    JobSimpleStatusLabels: {
        open: "Open",
        active: "Active",
        completed: "Completed",
        on_hold: "On Hold",
    },

    JobStatus: {
        OPEN: "open",
        ACTIVE: "active",
        COMPLETED: "completed",
        ON_HOLD: "on_hold",
        COMPLETED_NOT_HIRED: "completed_not_hired",
        COMPLETED_HIRED: "completed_hired",
        ACTIVE_NOT_FILLED: "active_not_filled",
        ACTIVE_FILLED: "active_filled",
    },
    // This file is generated by script, can't be modified manually
    JobStatusLabels: {
        open: "Open",
        active: "Active",
        completed: "Completed",
        on_hold: "On Hold",
        completed_not_hired: "Completed Not Hired",
        completed_hired: "Completed Hired",
        active_not_filled: "Active Not Filled",
        active_filled: "Active Filled",
    },

    JobTimezoneOverlapType: {
        PARTIAL: "partial",
        FULL: "full",
    },
    // This file is generated by script, can't be modified manually
    JobTimezoneOverlapTypeLabels: {
        partial: "Partial",
        full: "Full Day",
    },

    JobType: {
        GRANT: "grant",
        DIRECT_HIRE: "direct_hire",
        FREELANCE: "freelance",
    },
    // This file is generated by script, can't be modified manually
    JobTypeLabels: {
        grant: "Grant",
        direct_hire: "Employee",
        freelance: "Freelance",
    },

    JobsListOrder: {
        NEWEST: "-created",
        OLDEST: "created",
    },
    // This file is generated by script, can't be modified manually
    JobsListOrderLabels: {
        '-created': "Newest",
        created: "Oldest",
    },

    JoiningReason: {
        GET_A_JOB: "get_a_job",
        HIRE_TALENT: "hire_talent",
        REFER_AND_EARN_BTRST: "refer_and_earn_btrst",
        CAREER_HELP: "career_help",
    },
    // This file is generated by script, can't be modified manually
    JoiningReasonLabels: {
        get_a_job: "Get a job",
        hire_talent: "Hire talent",
        refer_and_earn_btrst: "Refer & earn BTRST",
        career_help: "Get career help",
    },

    LanguageSkillLevel: {
        LEVEL_6: "6",
        LEVEL_5: "5",
        LEVEL_4: "4",
        LEVEL_3: "3",
        LEVEL_2: "2",
        LEVEL_1: "1",
    },
    // This file is generated by script, can't be modified manually
    LanguageSkillLevelLabels: {
        6: "Native speaker",
        5: "Near native/fluent",
        4: "Excellent command/highly proficient in spoken and written",
        3: "Very good command",
        2: "Good command/good working knowledge",
        1: "Basic communication skills/working knowledge",
    },

    LeadSource: {
        CONNECTOR: "Connector",
    },
    // This file is generated by script, can't be modified manually
    LocationLevel: {
        CITY: "city",
        STATE: "state",
        COUNTRY: "country",
        CONTINENT: "continent",
    },
    // This file is generated by script, can't be modified manually
    MaxDuration: {
        ONE_MINUTE: "1",
        TWO_MINUTES: "2",
        THREE_MINUTES: "3",
        FOUR_MINUTES: "4",
        FIVE_MINUTES: "5",
        SIX_MINUTES: "6",
        SEVEN_MINUTES: "7",
        EIGHT_MINUTES: "8",
        NINE_MINUTES: "9",
        TEN_MINUTES: "10",
    },
    // This file is generated by script, can't be modified manually
    MaxDurationLabels: {
        1: "1 minute",
        2: "2 minutes",
        3: "3 minutes",
        4: "4 minutes",
        5: "5 minutes",
        6: "6 minutes",
        7: "7 minutes",
        8: "8 minutes",
        9: "9 minutes",
        10: "10 minutes",
    },

    MergeSyncStatus: {
        DISABLED: "DISABLED",
        DONE: "DONE",
        FAILED: "FAILED",
        PARTIALLY_SYNCED: "PARTIALLY_SYNCED",
        PAUSED: "PAUSED",
        SYNCED: "SYNCED",
        SYNCING: "SYNCING",
    },
    // This file is generated by script, can't be modified manually
    MessageType: {
        USER_REPORTED: "user_reported",
        USER_REPORTS_CANCELLED: "user_reports_cancelled",
        MESSAGE_REPORTED: "message_reported",
        HELP_OFFER_CREATED: "help_offer_created",
        HELP_OFFER_ACCEPTED: "help_offer_accepted",
        HELP_OFFER_DECLINED: "help_offer_declined",
        HELP_OFFER_DELETED: "help_offer_deleted",
        HELP_OFFER_COMPLETED: "help_offer_completed",
        HELP_OFFER_EXPIRED: "help_offer_expired",
        HELP_OFFER_REPORTED: "help_offer_reported",
        HELP_OFFER_BUDGET_CHANGED: "help_offer_budget_changed",
        HELP_OFFER_REFUND_REQUESTED: "help_offer_refund_requested",
        HELP_OFFER_REFUND_ISSUED: "help_offer_refund_issued",
        HELP_OFFER_REFUND_REQUEST_CANCELED: "help_offer_refund_requested_canceled",
        HELP_OFFER_MARKED_COMPLETE: "help_offer_marked_complete",
        HELP_OFFER_REVISION_REQUESTED: "help_offer_revision_requested",
        HELP_OFFER_REVIEW_SENT: "help_offer_review_sent",
        USER_MESSAGE: "user_message",
    },
    // This file is generated by script, can't be modified manually
    MimeType: {
        CSV: "text/csv",
        HTML: "text/html",
        JPEG: "image/jpeg",
        JSON: "application/json",
        PDF: "application/pdf",
        PLAIN_TEXT: "text/plain",
        PNG: "image/png",
        XML: "application/xml",
        ZIP: "application/zip",
    },
    // This file is generated by script, can't be modified manually
    NFTMetadataTraitType: {
        USERNAME: "username",
        MEMBERSHIP_TIER: "membership_tier",
        INTERNAL_USER_ID: "internal_user_id",
        APPROVED: "approved",
        TOKEN_BALANCE: "token_balance",
        PROFILE_LINK: "profile_link",
        MINT_TIMESTAMP: "mint_timestamp",
    },
    // This file is generated by script, can't be modified manually
    NFTMetadataTraitTypeLabels: {
        username: "User Name",
        membership_tier: "Membership Tier",
        internal_user_id: "Braintrust Internal User ID",
        approved: "Braintrust Approved",
        token_balance: "Token Balance",
        profile_link: "Braintrust Profile Link",
        mint_timestamp: "Mint Timestamp",
    },

    NFTMintingStatus: {
        MINTED: "minted",
        MINTING: "minting",
        NOT_MINTED: "not_minted",
    },
    // This file is generated by script, can't be modified manually
    NFTMintingStatusLabels: {
        minted: "Minted",
        minting: "Minting",
        not_minted: "Not Minted",
    },

    NFTTraitType: {
        USERNAME: "username",
        MEMBERSHIP_TIER: "membership_tier",
        INTERNAL_USER_ID: "internal_user_id",
        APPROVED: "approved",
        TOKEN_BALANCE: "token_balance",
        PROFILE_LINK: "profile_link",
    },
    // This file is generated by script, can't be modified manually
    NFTTraitTypeLabels: {
        username: "User Name",
        membership_tier: "Membership Tier",
        internal_user_id: "Braintrust Internal User ID",
        approved: "Braintrust Approved",
        token_balance: "Token Balance",
        profile_link: "Braintrust Profile Link",
    },

    NotificationChannel: {
        SITE: "site",
        EMAIL: "email",
    },
    // This file is generated by script, can't be modified manually
    NotificationGroup: {
        INVOICES: "invoices",
        JOBS: "jobs",
        MESSAGING: "messaging",
        REFERRALS: "referrals",
        REWARDS: "rewards",
        TALENT: "talent",
        CAREER_HELP: "career help",
        NEWSLETTER: "newsletter",
        NO_GROUP: "no_group",
    },
    // This file is generated by script, can't be modified manually
    NotificationTypes: {
        EMPLOYER_NEW_BID: "EMPLOYER_NEW_BID",
        EMPLOYER_NEW_BID_IN_SUBSCRIBED_JOB: "EMPLOYER_NEW_BID_IN_SUBSCRIBED_JOB",
        EMPLOYER_OFFER_UPDATES: "EMPLOYER_OFFER_UPDATES",
        EMPLOYER_OFFER_UPDATES_IN_SUBSCRIBED_JOB: "EMPLOYER_OFFER_UPDATES_IN_SUBSCRIBED_JOB",
        EMPLOYER_ACTIVE_JOB_UPDATES: "EMPLOYER_ACTIVE_JOB_UPDATES",
        FREELANCER_NEW_JOB_SAVED_FILTERS: "FREELANCER_NEW_JOB_SAVED_FILTERS",
        INVITING_TO_A_JOB: "INVITING_TO_A_JOB",
        APPLICATION_STATUS_UPDATES: "APPLICATION_STATUS_UPDATES",
        FREELANCER_NO_WITHDRAWAL_METHOD: "FREELANCER_NO_WITHDRAWAL_METHOD",
        REVIEW_THE_TEAM: "REVIEW_THE_TEAM",
        REFERRED_USER_UPDATES: "REFERRED_USER_UPDATES",
        TALENT_APPROVED_REFERRAL_BONUS: "TALENT_APPROVED_REFERRAL_BONUS",
        NEW_REWARD_RULE: "NEW_REWARD_RULE",
        BATCHED_MESSAGING_EMAIL_NOTIFICATIONS: "BATCHED_MESSAGING_EMAIL_NOTIFICATIONS",
        REFER_JOBS_AND_EARN: "REFER_JOBS_AND_EARN",
        EMPLOYER_NEW_INVOICES_AND_PAYMENTS: "EMPLOYER_NEW_INVOICES_AND_PAYMENTS",
        EMPLOYER_RECOMMENDED_TALENT: "EMPLOYER_RECOMMENDED_TALENT",
        UPGRADE_RENEWAL: "UPGRADE_RENEWAL",
        MY_POSTS_DIGEST: "MY_POSTS_DIGEST",
        POSTS_I_COMMENTED: "POSTS_I_COMMENTED",
        BOOKMARKED_POSTS: "BOOKMARKED_POSTS",
        SPACES_DIGEST: "SPACES_DIGEST",
        CAREER_HELP_HIGHLIGHTS: "CAREER_HELP_HIGHLIGHTS",
        CAREER_HELP_ONE_ON_ONE_REQUESTS: "CAREER_HELP_ONE_ON_ONE_REQUESTS",
        COMBINED_SPACES_FEED_HIGHLIGHTS: "COMBINED_SPACES_FEED_HIGHLIGHTS",
        NEWSLETTER: "NEWSLETTER",
        ALL_NOTIFICATIONS: "ALL_NOTIFICATIONS",
    },
    // This file is generated by script, can't be modified manually
    OfferDepositStatus: {
        PAID: "paid",
        NOT_PAID: "not_paid",
        REFUNDED: "refunded",
        REFUND_IN_PROGRESS: "refund_in_progress",
        PAYMENT_IN_PROGRESS: "payment_in_progress",
        PAYMENT_FAILED: "payment_failed",
        CANCELLED: "cancelled",
    },
    // This file is generated by script, can't be modified manually
    OfferHealthStatus: {
        GREEN: "green",
        YELLOW: "yellow",
        RED: "red",
    },
    // This file is generated by script, can't be modified manually
    OfferHealthStatusLabels: {
        green: "Green",
        yellow: "Yellow",
        red: "Red",
    },

    OfferStatus: {
        NEW: "new",
        UPDATED: "updated",
        ACCEPTED: "accepted",
        REJECTED: "rejected",
        CANCELLED: "cancelled",
        EXPIRED: "expired",
        WAITING_FOR_DEPOSIT: "waiting_for_deposit",
        WAITING_FOR_DEPOSIT_ACH_CONFIRMATION: "waiting_for_deposit_ach_confirmation",
        DEPOSIT_PAYMENT_FAILED: "deposit_payment_failed",
        DEPOSIT_PAYMENT_IN_PROGRESS: "deposit_payment_in_progress",
        EXTENDED: "extended",
    },
    // This file is generated by script, can't be modified manually
    OfferStatusLabels: {
        new: "New",
        updated: "Updated",
        accepted: "Accepted",
        rejected: "Declined",
        cancelled: "Cancelled",
        expired: "Expired",
        waiting_for_deposit: "Waiting for deposit",
        waiting_for_deposit_ach_confirmation: "Waiting for deposit - ACH payment method not confirmed",
        deposit_payment_failed: "Deposit payment has failed",
        deposit_payment_in_progress: "Deposit payment in progress",
        extended: "Contract updated",
    },

    OrganizationSize: {
        SELF_EMPLOYED: "SELF_EMPLOYED",
        LESS_THAN_10: "LESS_THAN_10",
        ELEVEN_TO_50: "ELEVEN_TO_50",
        FIFTY_ONE_TO_200: "FIFTY_ONE_TO_200",
        TWO_HUNDRED_ONE_TO_1000: "TWO_HUNDRED_ONE_TO_1000",
        OVER_1000: "OVER_1000",
    },
    // This file is generated by script, can't be modified manually
    OrganizationSizeLabels: {
        SELF_EMPLOYED: "Self-employed",
        LESS_THAN_10: "Less than 10",
        ELEVEN_TO_50: "11 - 50 employees",
        FIFTY_ONE_TO_200: "51 - 200 employees",
        TWO_HUNDRED_ONE_TO_1000: "201 - 1000 employees",
        OVER_1000: "Over 1000 employees",
    },

    PageMetadataSource: {
        DEFAULT: "default",
        JOB: "job",
        FREELANCER: "freelancer",
    },
    // This file is generated by script, can't be modified manually
    PaymentConfirmationSource: {
        FREELANCER: "freelancer",
        BRAINTRUST: "braintrust",
    },
    // This file is generated by script, can't be modified manually
    PaymentConfirmationSourceLabels: {
        freelancer: "Freelancer",
        braintrust: "Braintrust",
    },

    PaymentCurrency: {
        BTRST: "BTRST",
        USD: "USD",
    },
    // This file is generated by script, can't be modified manually
    PaymentStatus: {
        SUCCESS: "success",
        FAILURE: "failure",
        PROCESSING: "processing",
        REFUNDED: "refunded",
    },
    // This file is generated by script, can't be modified manually
    PaymentStatusLabels: {
        success: "Success",
        failure: "Failure",
        processing: "Processing",
        refunded: "Refunded",
    },

    PostCategoryColor: {
        MEDIUM_VIOLET: "--medium-violet",
        MEDIUM_BLUE: "--medium-blue",
        MEDIUM_GREEN: "--medium-green",
        MEDIUM_ORANGE: "--medium-orange",
        MEDIUM_YELLOW: "--medium-yellow",
        MEDIUM_TEAL: "--medium-teal",
        MEDIUM_RED: "--medium-red",
    },
    // This file is generated by script, can't be modified manually
    PostCategoryColorLabels: {
        '--medium-violet': "Medium Violet",
        '--medium-blue': "Medium Blue",
        '--medium-green': "Medium Green",
        '--medium-orange': "Medium Orange",
        '--medium-yellow': "Medium Yellow",
        '--medium-teal': "Medium Teal",
        '--medium-red': "Medium Red",
    },

    PostCelebratoryColorEnum: {
        WHITE: "White",
        PURPLE: "Purple",
        ORANGE: "Orange",
        BLUE: "Blue",
        TEAL: "Teal",
    },
    // This file is generated by script, can't be modified manually
    ReviewTagName: {
        WORK_QUALITY: "Work Quality",
        RESPONSIVENESS: "Responsiveness",
    },
    // This file is generated by script, can't be modified manually
    ReviewType: {
        WORK_REVIEW: "WorkReview",
        EMPLOYMENT_REVIEW: "EmploymentReview",
        COWORKER_REVIEW: "CoworkerReview",
        HELP_OFFER_REVIEW: "HelpOfferReview",
    },
    // This file is generated by script, can't be modified manually
    ReviewTypeLabels: {
        WorkReview: "Work review",
        EmploymentReview: "Employment review",
        CoworkerReview: "Coworker review",
        HelpOfferReview: "Help offer review",
    },

    RewardRuleType: {
        REFERRED_EMPLOYER_PAID_FOR_INVOICE: "ReferredEmployerPaidForInvoiceRewardType",
        CONNECTOR_REFERRED_EMPLOYER_PAID_FOR_INVOICE: "ConnectorReferredEmployerPaidForInvoiceRewardType",
        REFERRED_FREELANCER_HAS_THEIR_INVOICE_PAID: "ReferredFreelancerHasTheirInvoicePaidRewardType",
        REFERRED_FREELANCER_APPROVED: "ReferredFreelancerApprovedRewardType",
        REFER_TALENT_TO_A_JOB: "ReferTalentToAJobRewardType",
        FREELANCER_APPROVED: "FreelancerApprovedRewardType",
        MANUAL: "ManualRewardType",
    },
    // This file is generated by script, can't be modified manually
    RewardRuleTypeLabels: {
        ReferredEmployerPaidForInvoiceRewardType: "Referred employer paid for invoice",
        ConnectorReferredEmployerPaidForInvoiceRewardType: "Connector referred employer paid for invoice",
        ReferredFreelancerHasTheirInvoicePaidRewardType: "Referred freelancer has their invoice paid",
        ReferredFreelancerApprovedRewardType: "Referred freelancer approved",
        ReferTalentToAJobRewardType: "Refer talent to a job",
        FreelancerApprovedRewardType: "Freelancer approved",
        ManualRewardType: "Manual",
    },

    SafeWalletStatus: {
        FAILED: "FAILED",
        CREATED: "CREATED",
        PENDING: "PENDING",
        POSTPONED: "POSTPONED",
        DELETED: "DELETED",
    },
    // This file is generated by script, can't be modified manually
    SearchEventEntityType: {
        TALENT: "talent",
        JOBS: "jobs",
        CAREER_HELP: "career_help",
        BID: "bid",
    },
    // This file is generated by script, can't be modified manually
    SearchEventOption: {
        MAIN_SEARCH_BOX: "main_search_box",
        JOB_SEARCH_BOX: "job_search_box",
        TALENT_SEARCH_BOX: "talent_search_box",
        BID_SEARCH_BOX: "bid_search_box",
    },
    // This file is generated by script, can't be modified manually
    SharePostEventKind: {
        COPY_LINK: "copy_link",
        SHARE_VIA_TWITTER: "share_via_twitter",
        SHARE_VIA_LINKEDIN: "share_via_linkedin",
    },
    // This file is generated by script, can't be modified manually
    SkillLevel: {
        SOME_EXPERIENCE: "1",
        BEGINNER: "2",
        INTERMEDIATE: "3",
        ADVANCED: "4",
        EXPERT: "5",
    },
    // This file is generated by script, can't be modified manually
    SkillLevelLabels: {
        1: "Some Experience",
        2: "Beginner",
        3: "Intermediate",
        4: "Advanced",
        5: "Expert",
    },

    SkillSearchOperator: {
        AND: "and",
        OR: "or",
    },
    // This file is generated by script, can't be modified manually
    SkillSearchOperatorLabels: {
        and: "And",
        or: "Or",
    },

    SmartMatchingLabel: {
        RECOMMENDED_BY_MATCHER: "recommended_by_matcher",
        BAD: "bad",
        OK: "ok",
        GOOD: "good",
        GREAT: "great",
        UNAVAILABLE: "unavailable",
    },
    // This file is generated by script, can't be modified manually
    SmartMatchingLabels: {
        recommended_by_matcher: "Matcher Recommended",
        bad: "Bad match",
        ok: "OK match",
        good: "Good match",
        great: "Great match",
        unavailable: "Unavailable",
    },

    SmartMatchingStatus: {
        PROCESSING: "processing",
        COMPLETED: "completed",
    },
    // This file is generated by script, can't be modified manually
    SmartMatchingStatusLabels: {
        processing: "Processing",
        completed: "Completed",
    },

    SmartMatchingSummaryType: {
        POSITIVE: "positive",
        CONSTRUCTIVE: "constructive",
    },
    // This file is generated by script, can't be modified manually
    SpaceSuggestionBadge: {
        FOR_YOU: "for_you",
        INVITED_BY: "invited_by",
    },
    // This file is generated by script, can't be modified manually
    SpaceSuggestionBadgeLabels: {
        for_you: "For you",
        invited_by: "Invited by",
    },

    SpaceVisibility: {
        PUBLIC: "public",
    },
    // This file is generated by script, can't be modified manually
    SpaceVisibilityLabels: {
        public: "Public",
    },

    StickerEnum: {
        GREAT_IDEA: "great_idea",
        THANK_YOU: "thank_you",
        PLUS_ONE: "plus_one",
        NOT_QUITE_RIGHT: "not_quite_right",
        TELL_ME_MORE: "tell_me_more",
    },
    // This file is generated by script, can't be modified manually
    StickerEnumLabels: {
        great_idea: "Great idea!",
        thank_you: "Thank you!",
        plus_one: "I support this!",
        not_quite_right: "Not quite right",
        tell_me_more: "Tell me more",
    },

    StreamType: {
        PING: "ping",
        MESSAGE: "message",
        MESSAGE_MARK_AS_READ: "message_mark_as_read",
        POSTS_SUBSCRIBE: "posts_subscribe",
        POSTS_FEED_SUBSCRIBE: "posts_feed_subscribe",
        POSTS_UNSUBSCRIBE: "posts_unsubscribe",
        CALENDAR_LINK_PROMPT_SHOWN: "calendar_link_prompt_shown",
        SPACES_SUBSCRIBE: "spaces_subscribe",
        SPACES_UNSUBSCRIBE: "spaces_unsubscribe",
        MY_SPACES_LIST_SUBSCRIBE: "my_spaces_list_subscribe",
    },
    // This file is generated by script, can't be modified manually
    SystemMessageTargetType: {
        HELP_OFFER: "help_offer",
        USER: "user",
        MESSAGE: "message",
    },
    // This file is generated by script, can't be modified manually
    TalentAudience: {
        ALL: "all",
        APPROVED_TALENT: "approved_talent",
        UNAPPROVED_TALENT: "unapproved_talent",
    },
    // This file is generated by script, can't be modified manually
    TalentAudienceLabels: {
        all: "All",
        approved_talent: "Approved Talent",
        unapproved_talent: "Unapproved Talent",
    },

    TalentFilterYOE: {
        ONE_TO_THREE: "1-3",
        THREE_TO_FIVE: "3-5",
        FIVE_TO_TEN: "5-10",
        MORE_THAN_TEN: "10+",
    },
    // This file is generated by script, can't be modified manually
    TalentInviteStatus: {
        INVITED: "invited",
        VIEWED: "viewed",
        DECLINED: "declined",
    },
    // This file is generated by script, can't be modified manually
    TalentInviteStatusLabels: {
        invited: "Invited",
        viewed: "Invite viewed",
        declined: "Invite declined",
    },

    TalentListOrder: {
        NEWEST: "newest",
    },
    // This file is generated by script, can't be modified manually
    TalentListOrderLabels: {
        newest: "Newest",
    },

    TalentOffersSectionFilter: {
        CURRENT: "current",
        PAST: "past",
    },
    // This file is generated by script, can't be modified manually
    TalentOffersSectionFilterLabels: {
        current: "Current offers",
        past: "Past offers",
    },

    TokenAPIWithdrawalStatus: {
        CREATED: "CREATED",
        IN_PROGRESS: "IN_PROGRESS",
        PENDING_CONFIRMATION: "PENDING_CONFIRMATION",
        COMPLETED: "COMPLETED",
        FAILED: "FAILED",
        FAILED_INTERNALLY: "FAILED_INTERNALLY",
    },
    // This file is generated by script, can't be modified manually
    TokenTaxPaymentType: {
        TOKEN: "token",
        CASH: "cash",
    },
    // This file is generated by script, can't be modified manually
    TransferWiseAccountType: {
        CHECKING: "CHECKING",
        SAVINGS: "SAVINGS",
        SAVING: "SAVING",
    },
    // This file is generated by script, can't be modified manually
    TransferWiseAccountTypeLabels: {
        CHECKING: "Checking",
        SAVINGS: "Savings",
        SAVING: "Saving",
    },

    TransferWiseLegalType: {
        PRIVATE: "PRIVATE",
        BUSINESS: "BUSINESS",
    },
    // This file is generated by script, can't be modified manually
    TransferWiseLegalTypeLabels: {
        PRIVATE: "Private",
        BUSINESS: "Business",
    },

    UnsubscribeResultMessage: {
        MY_POSTS_DIGEST: "You have successfully unsubscribed from 'My posts' emails.",
        POSTS_I_COMMENTED: "You have successfully unsubscribed from 'Posts I've commented on' emails.",
        BOOKMARKED_POSTS: "You have successfully unsubscribed from 'Bookmarked posts' emails.",
        SPACES_DIGEST: "You have successfully unsubscribed from 'Spaces' emails.",
        CAREER_HELP_HIGHLIGHTS: "You have successfully unsubscribed from 'Career Help Highlights' emails.",
        CAREER_HELP_ONE_ON_ONE_REQUESTS: "You have successfully unsubscribed from '1:1 Paid Career Help Requests' emails.",
        COMBINED_SPACES_FEED_HIGHLIGHTS: "You have successfully unsubscribed from 'Spaces' and 'Career Help Highlights' emails.",
        UPGRADE_RENEWAL: "You have successfully unsubscribed from 'Upgrade renewal' emails.",
        EMPLOYER_RECOMMENDED_TALENT: "You have successfully unsubscribed from 'Recommended talent (monthly)' emails.",
        OTHER: "You have successfully unsubscribed from requested email type.",
        FAILED: "Couldn't unsubscribe.",
    },
    // This file is generated by script, can't be modified manually
    UpgradeType: {
        BOOST: "boost",
        INSIGHTS: "insights",
    },
    // This file is generated by script, can't be modified manually
    UserClicksEventCTALocation: {
        DASHBOARD: "dashboard",
        JOB_LIST: "job_list",
        MY_JOBS: "my_jobs",
        MY_APPLICATIONS: "my_applications",
    },
    // This file is generated by script, can't be modified manually
    UserClicksEventCTAName: {
        VIEW_JOB: "view_job",
        VIEW_ALL_JOBS: "view_all_jobs",
        GET_TO_KNOW_ME: "get_to_know_me",
        VIEW_APPLICATION: "view_application",
        VIEW_ALL_APPLICATIONS: "view_all_applications",
        RSVP: "rsvp",
        SHARE_VIA_LINKEDIN: "share_via_linkedin",
        SHARE_VIA_EMAIL: "share_via_email",
        SHARE_VIA_TWITTER: "share_via_twitter",
        REFER_TALENT: "refer_talent",
        COPY_LINK: "copy_link",
        LEARN_MORE_ABOUT_BRAINTRUST: "learn_more_about_braintrust",
        READ_ARTICLE_OR_NEWS: "read_article_or_news",
    },
    // This file is generated by script, can't be modified manually
    UserMessageType: {
        USER_REPORTED: "user_reported",
        USER_REPORTS_CANCELLED: "user_reports_cancelled",
        MESSAGE_REPORTED: "message_reported",
    },
    // This file is generated by script, can't be modified manually
    UserSuggestedForSpaceBadge: {
        REFERRED_YOU_TO_BRAINTRUST: "referred_you_to_braintrust",
        YOU_REFERRED_TO_BRAINTRUST: "you_referred_to_braintrust",
        HELPED_YOU: "helped_you",
        YOU_HAVE_HELPED: "you_have_helped",
        YOU_HAVE_MESSAGED: "you_have_messaged",
    },
    // This file is generated by script, can't be modified manually
    UserSuggestedForSpaceBadgeLabels: {
        referred_you_to_braintrust: "👋 Referred you to Braintrust",
        you_referred_to_braintrust: "💌 You referred to Braintrust",
        helped_you: "💡 Helped you",
        you_have_helped: "🤝 You've helped",
        you_have_messaged: "💬 You've messaged",
    },

    VisitsCountVisibility: {
        PUBLIC: "public",
        PRIVATE: "private",
    },
    // This file is generated by script, can't be modified manually
    VisitsCountVisibilityLabels: {
        public: "Public",
        private: "Private",
    },

    VisitsCounterType: {
        TALENT: "talent",
        BID: "bid",
        OFFER: "offer",
    },
    // This file is generated by script, can't be modified manually
    WalletActivityCategory: {
        CREDIT: "credit",
        DEBIT: "debit",
    },
    // This file is generated by script, can't be modified manually
    WalletActivitySourceKind: {
        WITHDRAWAL_TRANSACTION: "WithdrawalTransaction",
        HELP_OFFER: "HelpOffer",
        INVOICE: "Invoice",
        REWARD_RULE: "RewardRule",
        TOKEN_GRANT_PAYOUT: "TokenGrantPayout",
        REFERRED_USER: "ReferredUser",
        PAYMENT_TRANSACTION: "PaymentTransaction",
        OUTSIDE_TOKEN_TRANSFER: "OutsideTokenTransfer",
        COINBASE_TRANSACTION: "CoinbaseTransaction",
    },
    // This file is generated by script, can't be modified manually
    WalletActivitySourceType: {
        BALANCE_ITEM: "balance_item",
        WITHDRAWAL_TRANSACTION: "withdrawal_transaction",
    },
    // This file is generated by script, can't be modified manually
    WithdrawalTransactionStatus: {
        PROCESSING: "processing",
        SUCCESS: "success",
        FAILED: "failed",
        BOUNCED: "bounced",
        AWAITING: "awaiting",
        LINK_EXPIRED: "link_expired",
    },
    // This file is generated by script, can't be modified manually
    WithdrawalTransactionStatusLabels: {
        processing: "Processing",
        success: "Success",
        failed: "Failed",
        bounced: "Bounced",
        awaiting: "Awaiting email confirmation",
        link_expired: "Confirmation link expired",
    },

} as const;

